import accesedition from "@content/advanced/images/acces-edition-etude.png";
import ajoutcondtionnav from "@content/tutorial/images/ajouter-navigation-conditionnelle.png";
import conditions from "@content/tutorial/images/conditions-dactivation.png";
import icone from "@content/advanced/images/icone-navigation-conditionnelle.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatConditionNav() {
  return (
    <PageLayout title="Conditional Navigation" roles={["admin"]}>
      <p>
        When creating an eCRF, you can choose to display certain questions only
        if a specific answer has been given to a previous question (
        <em>
          Example: "Patient's Gender: M / F," if the answer is "F," then unlock
          the question "Is the patient pregnant?"{" "}
        </em>
        ). This feature is called conditional navigation.
      </p>
      <Section
        id="accessing-conditional-navigation"
        title="Accessing Conditional Navigation"
      >
        <p>
          Conditional navigation applies to{" "}
          <a href="/en/advanced/models">
            <b>form templates</b>
          </a>
          , so you need to go through the <b>Design</b> menu and then the{" "}
          <b>Study Editing</b> tab.
        </p>
        <img src={accesedition} alt="Study Editing Tab" className="centered" />
      </Section>
      <Section
        id="applying-conditional-navigation"
        title="Applying Conditional Navigation"
      >
        <p>
          To apply it to a variable, hover your cursor over it and click on the{" "}
          <b>Edit Activation Condition</b> icon.
        </p>
        <img
          src={ajoutcondtionnav}
          alt="Edit Activation Conditions"
          className="centered"
        />
        <p>
          In the window that opens, click on the arrows next to{" "}
          <em>No variable selected</em>, then choose the variable that will
          activate the current variable. Then click the <b>+ Condition</b>{" "}
          button and define the result condition that will activate the current
          variable.
        </p>
        <p>
          It is possible to apply up to two conditions for non-boolean
          variables.
        </p>
        <img
          src={conditions}
          alt="Activation Conditions"
          className="centered"
        />
        <p>
          The possible conditions vary according to the type of variable chosen
          for reference:
        </p>
        <div className="responsive-table">
          <table>
            <tr>
              <td>Type of Variable</td>
              <td>Possible Activation Conditions</td>
            </tr>
            <tr>
              <td>Measurement, Calculation</td>
              <td>
                If the result is: less than (&lt;), less than or equal (≤),
                equal (=), not equal (≠), greater than (&gt;), greater than or
                equal (≥) to the condition.
              </td>
            </tr>
            <tr>
              <td>Date and Time</td>
              <td>
                If the result is: less than (&lt;), less than or equal (≤),
                greater than (&gt;), greater than or equal (≥) to the condition.
              </td>
            </tr>
            <tr>
              <td>Boolean</td>
              <td>If the result is "True" or "False."</td>
            </tr>
            <tr>
              <td>Single Choice</td>
              <td>If the result matches a specific choice.</td>
            </tr>
            <tr>
              <td>Multiple Choice</td>
              <td>
                If the number of selected choices is: less than (&lt;), less
                than or equal (≤), equal (=), not equal (≠), greater than
                (&gt;), greater than or equal (≥) to the condition.
              </td>
            </tr>
          </table>
        </div>
        <Note type="note">
          Conditional navigation cannot use <b>text</b> or <b>static content</b>{" "}
          variables as references.
        </Note>
        <p>
          Press <b>Validate</b> to confirm your choice. A small green icon will
          appear next to your variable to indicate that it will be displayed
          conditionally.
        </p>
        <img
          src={icone}
          alt="Conditional Navigation Active"
          className="centered"
        />
      </Section>
    </PageLayout>
  );
}
